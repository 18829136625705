import { NavLink } from "react-router-dom";

import { ReactComponent as InstagramIcon } from "@/assets/images/icons/instagram-icon.svg";

type MenuItem = {
  slideIndex: number;
  title: string;
  path: string;
  hidden?: boolean;
};

type ComponentProps = {
  navbarItems: MenuItem[];
  linkRefs: React.MutableRefObject<(HTMLAnchorElement | null)[]>;
  handleClick: (e: React.MouseEvent, slideIndex: number) => void;
  activeItemTitle: string | undefined;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const NavbarMenu = ({
  navbarItems,
  linkRefs,
  handleClick,
  activeItemTitle,
  isOpen,
  setIsOpen,
}: ComponentProps) => {
  const renderMenuItems = () => (
    <ul
      className={`navbar__menu${isOpen ? " navbar__menu--open" : ""}`}
      data-swiper-parallax="-200"
    >
      {navbarItems
        .filter(({ hidden }) => !hidden)
        .map(({ title, path, slideIndex }) => (
          <li
            className={`navbar__menu-item${
              title === activeItemTitle ? " navbar__menu-item--active" : ""
            }`}
            key={title}
          >
            <NavLink
              className="navbar__menu-link button"
              to={path}
              onClick={(e) => handleClick(e, slideIndex)}
              ref={(link) => (linkRefs.current[slideIndex] = link)}
              data-title={title}
            >
              <span>{title}</span>
            </NavLink>
          </li>
        ))}
      <li className="navbar__menu-item">
        <NavLink
          to="https://www.instagram.com/oduwan.bikes/"
          className="header__icon"
          target="_blank"
          rel="noopener noreferrer"
        >
          <InstagramIcon />
        </NavLink>
      </li>
    </ul>
  );

  return isOpen ? (
    <div className="overlay" onClick={() => setIsOpen(false)}>
      {renderMenuItems()}
    </div>
  ) : (
    renderMenuItems()
  );
};
